<template>
    <v-form ref="billForm" v-model="billValid" lazy-validation>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    outlined
                                    :disabled="paidPayments"
                                    v-on:change="changePlan"
                                    hide-details
                                    dense
                                    v-model="planGuid"
                                    :items="plans"
                                    item-text="title"
                                    item-value="planGuid"
                                    :label="$t('itemTemplate.templateName')"
                                ></v-autocomplete
                            ></v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                    :disabled="paidPayments"
                                    outlined
                                    @change="createPayments"
                                    hide-details
                                    dense
                                    persistent-hint
                                    :rules="rules"
                                    v-model="bill.installment.installmentType"
                                    :items="installmentTypes"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('installments.installmentType')"
                                ></v-select
                            ></v-col>
                            <v-col cols="12" md="4"
                                ><v-text-field
                                    outlined
                                    :disabled="paidPayments"
                                    @change="createPayments"
                                    type="number"
                                    min="0"
                                    dense
                                    v-model="bill.installment.paymentIncrement"
                                    :rules="rules"
                                    :label="$t('installments.paymentIncrement')"
                                    hide-details
                                ></v-text-field
                            ></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    :disabled="
                                        paidPayments &&
                                            bill.installment.installmentState ==
                                                2
                                    "
                                    @change="createPayments"
                                    type="number"
                                    min="0"
                                    dense
                                    v-model="bill.installment.paymentCount"
                                    :rules="rules"
                                    :label="$t('installments.paymentCount')"
                                    hide-details
                                ></v-text-field
                            ></v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                    outlined
                                    :disabled="paidPayments"
                                    @change="createPayments"
                                    hide-details
                                    dense
                                    v-model="bill.installment.installmentState"
                                    persistent-hint
                                    :rules="rules"
                                    :items="installmentStates"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('installments.installmentState')"
                                ></v-select
                            ></v-col>
                            <v-col cols="12" md="4">
                                <template>
                                    <div>
                                        <v-menu
                                            ref="menu2"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-text-field
                                                    hide-details
                                                    outlined
                                                    dense
                                                    :disabled="paidPayments"
                                                    v-model="
                                                        bill.installment
                                                            .firstPaymentDate
                                                    "
                                                    :rules="rules"
                                                    :label="
                                                        $t(
                                                            'installments.firstPaymentDate'
                                                        )
                                                    "
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                :disabled="paidPayments"
                                                :active-picker.sync="
                                                    activePicker2
                                                "
                                                v-model="
                                                    bill.installment
                                                        .firstPaymentDate
                                                "
                                                @change="createPayments"
                                                :max="
                                                    new Date(
                                                        Date.now() -
                                                            new Date().getTimezoneOffset() *
                                                                60000
                                                    )
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                min="1950-01-01"
                                            ></v-date-picker>
                                        </v-menu>
                                    </div> </template
                            ></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4"
                                ><v-text-field
                                    type="number"
                                    min="0"
                                    hide-details
                                    :disabled="paidPayments"
                                    @change="calculateTotalAmount"
                                    :label="$t('itemTemplate.discount')"
                                    outlined
                                    dense
                                    v-model="bill.totalDiscount"
                                >
                                </v-text-field
                            ></v-col>
                            <v-col cols="12" md="4"
                                ><v-text-field
                                    :disabled="paidPayments"
                                    type="number"
                                    min="0"
                                    @change="calculateTotalAmount"
                                    hide-details
                                    :label="$t('itemTemplate.extra')"
                                    outlined
                                    dense
                                    v-model="bill.totalExtra"
                                >
                                </v-text-field
                            ></v-col>
                            <v-col cols="12" md="4"
                                ><v-text-field
                                    type="number"
                                    hide-details
                                    readonly
                                    :label="$t('bill.totalAmount')"
                                    outlined
                                    dense
                                    v-model="bill.totalAmount"
                                >
                                </v-text-field
                            ></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6"
                                ><v-text-field
                                    class="font-weight-black centered-input"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    type="number"
                                    v-model="receivedAmount"
                                    :label="$t('bill.receivedAmount')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="12" md="6"
                                ><v-text-field
                                    class="font-weight-black centered-input"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    type="number"
                                    v-model="remainingAmount"
                                    :label="$t('bill.remainingAmount')"
                                ></v-text-field
                            ></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    :disabled="paidPayments"
                                    outlined
                                    rows="1"
                                    auto-grow
                                    hide-details
                                    v-model="bill.notes"
                                    :label="$t('itemTemplate.notes')"
                                ></v-textarea
                            ></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    class="myTable"
                                    :headers="billHeaders"
                                    :items="bill.billBodies"
                                    :loading="loading"
                                    :loading-text="$t('loading')"
                                    hide-default-footer
                                    :height="300"
                                    :disable-pagination="true"
                                >
                                    <template
                                        v-slot:[`item.itemGuid`]="{
                                            item
                                        }"
                                    >
                                        <v-select
                                            hide-details
                                            readonly
                                            solo
                                            flat
                                            :disabled="paidPayments"
                                            dense
                                            persistent-hint
                                            :rules="rules"
                                            v-model="item.itemGuid"
                                            :items="items"
                                            item-text="itemName"
                                            item-value="itemGuid"
                                        ></v-select>
                                    </template>
                                    <template
                                        v-slot:[`item.price`]="{
                                            item
                                        }"
                                    >
                                        <v-text-field
                                            type="number"
                                            min="0"
                                            solo
                                            flat
                                            :disabled="paidPayments"
                                            dense
                                            v-model="item.price"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <template
                                        v-slot:[`item.notes`]="{
                                            item
                                        }"
                                    >
                                        <v-text-field
                                            dense
                                            solo
                                            flat
                                            :disabled="paidPayments"
                                            v-model="item.notes"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <template
                                        v-slot:[`item.actions`]="{
                                            item
                                        }"
                                    >
                                        <v-tooltip top v-if="!paidPayments">
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon
                                                    color="red"
                                                    @click="
                                                        deleteBillItem(item)
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-delete-outline
                                                </v-icon>
                                            </template>
                                            <span> {{ $t("delete") }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    outlined
                                    @change="changeBill"
                                    hide-details
                                    dense
                                    persistent-hint
                                    v-model="selectedBillGuid"
                                    :items="fiscalYearTitles"
                                    item-text="title"
                                    item-value="billGuid"
                                    :label="
                                        $t(
                                            'school.studentBillsAccordingToFiscalYear'
                                        )
                                    "
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                ><v-data-table
                                    class="myTable"
                                    :headers="paymentsHeaders"
                                    :items="bill.payments"
                                    :loading="loading"
                                    :loading-text="$t('loading')"
                                    hide-default-footer
                                    :height="490"
                                    :disable-pagination="true"
                                    @click:row="setIndex"
                                >
                                    <template
                                        v-slot:[`item.paymentAmount`]="{
                                            item
                                        }"
                                    >
                                        <v-text-field
                                            type="number"
                                            dense
                                            solo
                                            flat
                                            :rules="rules"
                                            :readonly="
                                                item.isPaid ||
                                                    bill.installment
                                                        .installmentState == 2
                                            "
                                            v-model="item.paymentAmount"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <template
                                        v-slot:[`item.actions`]="{
                                            item
                                        }"
                                    >
                                        <v-row>
                                            <v-col cols="6">
                                                <v-tooltip
                                                    v-if="bill.billGuid != null"
                                                    top
                                                >
                                                    <template
                                                        v-if="
                                                            (isInRole(40) &&
                                                                item.isPaid ==
                                                                    false) ||
                                                                (isInRole(41) &&
                                                                    item.isPaid)
                                                        "
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            :color="
                                                                item.isPaid
                                                                    ? 'red'
                                                                    : 'blue'
                                                            "
                                                            @click="
                                                                payPayment(item)
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                item.isPaid
                                                                    ? "mdi-currency-usd-off"
                                                                    : "mdi-currency-usd"
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{
                                                            item.isPaid
                                                                ? $t("cancel")
                                                                : $t(
                                                                      "payments.pay"
                                                                  )
                                                        }}</span
                                                    >
                                                </v-tooltip></v-col
                                            >
                                            <v-col cols="6"
                                                ><v-tooltip
                                                    top
                                                    v-if="
                                                        isInRole(42) &&
                                                            bill.billGuid !=
                                                                null &&
                                                            item.isPaid == true
                                                    "
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="green"
                                                            @click="
                                                                printPayment(
                                                                    item.paymentGuid
                                                                )
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-printer-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{ $t("print") }}</span
                                                    >
                                                </v-tooltip></v-col
                                            >
                                        </v-row>
                                    </template>
                                    <template
                                        v-slot:[`item.isPaid`]="{
                                            item
                                        }"
                                    >
                                        <v-icon
                                            :color="
                                                item.isPaid
                                                    ? 'primary'
                                                    : 'yellow darken-2'
                                            "
                                            >{{
                                                item.isPaid
                                                    ? "mdi-check"
                                                    : "mdi-alert-outline"
                                            }}</v-icon
                                        >
                                    </template>
                                    <template
                                        v-slot:[`item.receivedDate`]="{
                                            item
                                        }"
                                    >
                                        <v-menu
                                            ref="menu3"
                                            v-model="menu3"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            v-if="
                                                rowIndex ==
                                                    bill.payments.indexOf(
                                                        item
                                                    ) && !item.isPaid
                                            "
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-text-field
                                                    hide-details
                                                    dense
                                                    solo
                                                    flat
                                                    v-model="item.receivedDate"
                                                    :rules="rules"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                :max="
                                                    new Date(
                                                        Date.now() -
                                                            new Date().getTimezoneOffset() *
                                                                60000
                                                    )
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                min="2022-01-01"
                                                :active-picker.sync="
                                                    activePicker3
                                                "
                                                v-model="item.receivedDate"
                                            ></v-date-picker>
                                        </v-menu>
                                        <span v-else>{{
                                            item.isPaid &&
                                            item.receivedDate != null
                                                ? $moment(
                                                      item.receivedDate
                                                  ).format("yyyy-MM-DD")
                                                : ""
                                        }}</span>
                                    </template>
                                    <template
                                        v-slot:[`item.paymentDate`]="{
                                            item
                                        }"
                                    >
                                        {{
                                            item.paymentDate
                                                | moment("yyyy-MM-DD")
                                        }}
                                    </template>
                                </v-data-table></v-col
                            >
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary darken-1 white--text"
                    @click="saveBill"
                    v-if="isInRole(37)"
                    :disabled="!valid"
                    :min-width="100"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("school.saveBill") }}
                </v-btn>
                <v-btn
                    v-if="isInRole(39) && bill.billGuid != null"
                    @click="print"
                    color="primary darke-1 white--text"
                    text
                    :disabled="!valid"
                    :min-width="100"
                >
                    <v-icon>mdi-printer-outline</v-icon>
                    {{ $t("print") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="closeBill">
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Un/Pay Confirmation-->
        <confirm-dialog
            :openDialog="dialogPayConfirm"
            :onClicked="savePayment"
            :onClose="closeConfirm"
            toolBarColor="primary"
            dialogTitle=" "
        ></confirm-dialog>
    </v-form>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import moment from "moment";

export default {
    components: { ConfirmDialog },
    props: ["studentGuid", "customerGuid"],
    data() {
        return {
            rowIndex: -1,
            selectedBillGuid: null,
            tempPlans: [],
            fiscalYearTitles: [],
            bills: [],
            paidPayments: false,
            paymentObject: null,
            dialogPayConfirm: false,
            remainingAmount: null,
            receivedAmount: null,
            payments: [],
            items: [],
            planGuid: null,
            planItems: [],
            plans: [],
            billHeaders: [
                {
                    text: this.$t("billBody.item"),
                    value: "itemGuid"
                },
                {
                    text: this.$t("billBody.price"),
                    value: "price"
                },
                {
                    text: this.$t("billBody.notes"),
                    value: "notes"
                },
                { text: "", value: "actions" }
            ],
            installmentTypes: [
                {
                    text: this.$t("installmentTypes.daily"),
                    value: 1
                },
                {
                    text: this.$t("installmentTypes.weekly"),
                    value: 2
                },
                {
                    text: this.$t("installmentTypes.monthly"),
                    value: 3
                },
                {
                    text: this.$t("installmentTypes.yearly"),
                    value: 4
                }
            ],
            installmentStates: [
                {
                    text: this.$t("installmentStates.free"),
                    value: 1
                },
                {
                    text: this.$t("installmentStates.equal"),
                    value: 2
                }
            ],
            billSetting: null,
            activePicker: null,
            activePicker2: null,
            activePicker3: null,
            date: null,
            menu: false,
            valid: true,
            billValid: true,
            loading: true,
            dialog: false,
            paymentsHeaders: [
                {
                    text: this.$t("payments.paymentNumber"),
                    value: "paymentNumber"
                },
                {
                    text: this.$t("payments.paymentDate"),
                    value: "paymentDate"
                },
                {
                    text: this.$t("payments.receivedDate"),
                    value: "receivedDate"
                },
                {
                    text: this.$t("payments.paymentAmount"),
                    value: "paymentAmount"
                },
                {
                    text: this.$t("payments.isPaid"),
                    value: "isPaid"
                },
                { text: "", value: "actions" }
            ],
            menu2: false,
            menu3: false,
            bill: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: [],
                installment: {
                    installmentGuid: null,
                    billGuid: null,
                    installmentType: null,
                    installmentState: null,
                    paymentCount: null,
                    paymentIncrement: null,
                    firstPaymentDate: null,
                    guarantor1Name: null,
                    guarantor1Phone: null,
                    guarantor1Address: null,
                    guarantor1FileUrl: null,
                    guarantor2Name: null,
                    guarantor2Phone: null,
                    guarantor2Address: null,
                    guarantor2FileUrl: null,
                    guarantyBy: null
                },
                payments: []
            },
            defaultBill: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: [],
                installment: {
                    installmentGuid: null,
                    billGuid: null,
                    installmentType: null,
                    installmentState: null,
                    paymentCount: null,
                    paymentIncrement: null,
                    firstPaymentDate: null,
                    guarantor1Name: null,
                    guarantor1Phone: null,
                    guarantor1Address: null,
                    guarantor1FileUrl: null,
                    guarantor2Name: null,
                    guarantor2Phone: null,
                    guarantor2Address: null,
                    guarantor2FileUrl: null,
                    guarantyBy: null
                },
                payments: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        try {
            if (
                this.studentGuid != null &&
                this.studentGuid != undefined &&
                this.studentGuid != "00000000-0000-0000-0000-000000000000"
            ) {
                this.getBillSettings();
                this.getStudentBills(this.studentGuid);
            }
            if (this.isInRole(43)) {
                this.loading = true;
                this.refreshData();
            } else this.redirectUnauthorizedUsers();
        } catch (error) {
            console.log(error);
        }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "DATE"));
        },
        menu2(val) {
            val && setTimeout(() => (this.activePicker2 = "DATE"));
        },
        menu3(val) {
            val && setTimeout(() => (this.activePicker3 = "DATE"));
        },
        "bill.billBodies": {
            handler: function() {
                this.calculateTotalAmount();
                this.remainingAndReceived();
            },
            deep: true
        }
    },
    methods: {
        remainingAndReceived() {
            try {
                if (this.bill == null || this.bill == undefined) return;

                var received = 0;

                for (
                    let index = 0;
                    index < this.bill.payments.length;
                    index++
                ) {
                    if (this.bill.payments[index].isPaid)
                        received += Number(
                            this.bill.payments[index].paymentAmount
                        );
                }

                this.receivedAmount = received;
                this.remainingAmount = this.bill.totalNet - this.receivedAmount;
            } catch (error) {
                console.log(error);
            }
        },
        closeConfirm() {
            this.dialogPayConfirm = false;
        },
        payPayment(payment) {
            this.dialogPayConfirm = true;
            this.paymentObject = payment;
        },
        getBillSettings() {
            try {
                axios
                    .get("Bills/GetBillSettingsByType?type=InstallmentsSale")
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.billSetting = response.data.data[0];

                            this.bill.billType = "InstallmentsSale";
                            this.bill.billSettingGuid =
                                response.data.data[0].billSettingGuid;

                            this.bill.currencyGuid =
                                response.data.data[0].currencyGuid;

                            this.bill.currencyValue = 1;

                            this.bill.accountGuid =
                                response.data.data[0].accountGuid;
                            this.bill.accountContraGuid =
                                response.data.data[0].accountContraGuid;
                            this.bill.stockGuid =
                                response.data.data[0].stockGuid;
                            this.bill.payType = 2;
                            this.priceType = response.data.data[0].priceType;

                            /* this.bill.installment.installmentType =
                                response.data.data[0].installmentType;
                            this.bill.installment.paymentCount =
                                response.data.data[0].paymentNumber;
                            this.bill.installment.paymentIncrement =
                                response.data.data[0].paymentIncrement;
                            this.bill.installment.firstPaymentDate = moment(
                                new Date()
                            ).format("yyyy-MM-DD"); */
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        calculateTotalAmount() {
            try {
                if (
                    this.bill != null ||
                    this.bill != undefined ||
                    this.bill.billBodies != undefined ||
                    this.bill.billBodies != null ||
                    this.bill.billBodies.length != 0
                ) {
                    var billBodiesTotal = Number(
                        this.bill.billBodies.reduce((a, b) => +a + +b.price, 0)
                    );

                    /* var remainingFromBills =
                        this.bill.remainingAmount != null ||
                        this.bill.remainingAmount != undefined
                            ? Number(this.bill.remainingAmount)
                            : 0; */

                    this.bill.totalAmount =
                        billBodiesTotal -
                        Number(this.bill.totalDiscount) +
                        Number(this.bill.totalExtra);

                    this.bill.totalNet = this.bill.totalAmount;
                }
            } catch (error) {
                console.log(error);
            }
        },
        checkPaidPayments() {
            try {
                if (this.bill == null || this.bill == undefined) return;
                var paidPayments = this.bill.payments.filter(function(obj) {
                    return obj.isPaid == true;
                });

                if (paidPayments != null && paidPayments.length != 0)
                    this.paidPayments = true;
                else this.paidPayments = false;
            } catch (error) {
                console.log(error);
            }
        },
        createPayments() {
            try {
                if (
                    this.bill != null &&
                    this.bill != undefined &&
                    this.bill.installment.installmentType != null &&
                    this.bill.installment.paymentCount != null &&
                    this.bill.installment.paymentCount != 0 &&
                    this.bill.installment.paymentIncrement != null &&
                    this.bill.installment.paymentIncrement != 0 &&
                    this.bill.installment.firstPaymentDate != null &&
                    this.bill.totalAmount != null &&
                    this.bill.totalAmount != 0
                ) {
                    var paidPayments = this.bill.payments.filter(function(obj) {
                        return obj.isPaid == true;
                    });

                    this.bill.payments = [];

                    //Payments dates start from
                    //it should be ordered acc.
                    var lastDate =
                        paidPayments.length == 0
                            ? new Date(this.bill.installment.firstPaymentDate)
                            : paidPayments[paidPayments.length - 1].paymentDate;

                    var frequentAmount = 0;

                    if (paidPayments.length == 0)
                        frequentAmount =
                            this.bill.totalAmount /
                            this.bill.installment.paymentCount;
                    else
                        frequentAmount =
                            this.remainingAmount /
                            (this.bill.installment.paymentCount -
                                paidPayments.length);

                    this.bill.payments = [...paidPayments];

                    var timePeriod = "";
                    switch (this.bill.installment.installmentType) {
                        case 1:
                            timePeriod = "days";
                            break;
                        case 2:
                            timePeriod = "weeks";
                            break;
                        case 3:
                            timePeriod = "months";
                            break;
                        case 4:
                            timePeriod = "years";
                            break;
                        default:
                            break;
                    }

                    if (paidPayments.length != 0)
                        lastDate = moment(lastDate).add(
                            Number(this.bill.installment.paymentIncrement),
                            timePeriod
                        );

                    for (
                        let i = 0;
                        i <
                        this.bill.installment.paymentCount -
                            paidPayments.length;
                        i++
                    ) {
                        var payment = {
                            paymentNumber: i + 1,
                            paymentDate: moment(lastDate).format("yyyy-MM-DD"),
                            receivedDate: null,
                            //receivedDate: moment(lastDate).format("yyyy-MM-DD"),
                            paymentAmount: frequentAmount,
                            isPaid: false
                        };

                        lastDate = moment(lastDate).add(
                            Number(this.bill.installment.paymentIncrement),
                            timePeriod
                        );
                        this.bill.payments.push(payment);
                    }

                    //re-arrange payments numbers
                    for (let i = 0; i < this.bill.payments.length; i++) {
                        this.bill.payments[i].paymentNumber = i + 1;
                    }
                }
            } catch (error) {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(error);
            }
        },
        savePayment() {
            try {
                var previousIndex =
                    this.bill.payments.indexOf(this.paymentObject) - 1;
                var previousPayment =
                    previousIndex != -1
                        ? this.bill.payments[previousIndex]
                        : null;

                if (this.paymentObject.isPaid == false) {
                    if (
                        this.paymentObject.paymentAmount < 0 ||
                        this.paymentObject.paymentAmount == 0
                    ) {
                        this.$toast.warning(
                            this.$t("error.paymentAmountCannotBeNegative")
                        );
                        this.dialogPayConfirm = false;
                        return;
                    }

                    if (
                        this.paymentObject.paymentAmount > this.remainingAmount
                    ) {
                        this.$toast.warning(
                            this.$t(
                                "error.paymentAmountCannotBeGreaterThanRemainingAmount"
                            )
                        );
                        this.dialogPayConfirm = false;
                        return;
                    }

                    if (this.paymentObject.receivedDate == null) {
                        this.$toast.warning(
                            this.$t("error.pleaseSelectReceivedDate")
                        );
                        this.dialogPayConfirm = false;
                        return;
                    }

                    if (
                        (previousPayment != null && previousPayment.isPaid) ||
                        this.paymentObject.paymentNumber == 1
                    ) {
                        axios
                            .post("Payments/Pay", this.paymentObject)
                            .then(response => {
                                if (response.data.status == "Successful") {
                                    this.getStudentBills(this.studentGuid);
                                    // this.paymentObject.isPaid = true;
                                    // this.paymentObject.receivedDate =
                                    //     response.data.data.receivedDate;
                                    // this.paymentObject.voucherGuid =
                                    //     response.data.data.voucherGuid;

                                    // this.bill.payments[
                                    //     this.bill.payments.indexOf(
                                    //         this.paymentObject
                                    //     )
                                    // ] = this.paymentObject;
                                    // this.remainingAndReceived();
                                    // this.checkPaidPayments();
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            });
                    } else {
                        this.$toast.error(this.$t("CannotPay"));
                    }
                } else {
                    axios
                        .post("Payments/Unpay", this.paymentObject)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.paymentObject.isPaid = false;
                                this.paymentObject.receivedDate = null;
                                this.paymentObject.voucherGuid =
                                    response.data.data.voucherGuid;
                                this.bill.payments[
                                    this.bill.payments.indexOf(
                                        this.paymentObject
                                    )
                                ] = this.paymentObject;
                                this.remainingAndReceived();
                                this.checkPaidPayments();
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
                }
                this.dialogPayConfirm = false;
            } catch (error) {
                console.log(error);
            }
        },
        changePlan() {
            try {
                if (this.planGuid != null || this.planGuid != undefined) {
                    var selectedPlan = this.planGuid;
                    this.bill.billBodies = this.plans.filter(function(obj) {
                        return obj.planGuid == selectedPlan;
                    })[0].planItems;
                }
            } catch (error) {
                console.log(error);
            }
        },
        deleteBillItem(item) {
            this.bill.billBodies.splice(this.bill.billBodies.indexOf(item), 1);
        },
        closeBill() {
            try {
                this.bill = Object.assign(this.bill, this.defaultBill);
                this.$refs.billForm.reset();
                this.$refs.billForm.resetValidation();

                axios
                    .get("Plans/Get")
                    .then(response => {
                        this.plans = response.data.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                this.$emit("close");
            } catch (error) {
                console.log(error);
            }
        },
        refreshData() {
            try {
                this.getBillSettings();

                axios
                    .get("Items/Get")
                    .then(response => {
                        this.items = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                axios
                    .get("Plans/Get")
                    .then(response => {
                        this.plans = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        saveBill() {
            try {
                if (this.$refs.billForm.validate()) {
                    if (this.customerGuid == null) {
                        this.$toast.error(
                            this.$t("error.FillStudentFormFirst")
                        );
                        return;
                    }

                    if (
                        this.bill.billGuid == undefined ||
                        this.bill.billGuid == null
                    ) {
                        this.bill.billGuid =
                            "00000000-0000-0000-0000-000000000000";
                        this.bill.customerGuid = this.customerGuid;
                        this.bill.installment.installmentGuid =
                            "00000000-0000-0000-0000-000000000000";
                        this.bill.currencyValue = 1;
                        this.billDate = moment(new Date()).format("yyyy-MM-DD");
                    }

                    if (
                        this.bill.payments == null ||
                        this.bill.payments.length == 0
                    ) {
                        this.$toast.error(this.$t("error.NoPaymentsFound"));
                        return;
                    }

                    var stockGuid = this.bill.stockGuid;

                    //add new properties to the bill bodies
                    this.bill.billBodies.forEach(function(element) {
                        element.barcode = 0;
                        element.unitType = 0;
                        element.quantity = 0;
                        element.bounceQuantity = 0;
                        element.discount = 0;
                        element.extra = 0;
                        element.netPrice = element.price;
                        element.finalPrice = element.price;
                        element.costPrice = element.price;
                        element.totalPrice = element.price;
                        element.profit = 0;
                        element.stockGuid = stockGuid;
                    });

                    this.bill.totalNet = this.bill.totalAmount;

                    if (
                        this.bill.totalDiscount == null ||
                        this.bill.totalDiscount == undefined ||
                        this.bill.totalDiscount == ""
                    )
                        this.bill.totalDiscount = 0;

                    if (
                        this.bill.totalExtra == null ||
                        this.bill.totalExtra == undefined ||
                        this.bill.totalExtra == ""
                    )
                        this.bill.totalExtra = 0;

                    this.loading = true;

                    if (
                        this.bill.billNumber == null ||
                        this.bill.billNumber == undefined ||
                        this.bill.billNumber == ""
                    )
                        this.bill.billNumber = 1;
                    this.bill.payType = 2;

                    axios
                        .post("Bills/SaveBill", this.bill)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );

                                this.bill = response.data.data[0];
                                this.bill.installment.firstPaymentDate = moment(
                                    this.bill.installment.firstPaymentDate
                                ).format("yyyy-MM-DD");
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.refreshData();
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.refreshData();
                            this.remainingAndReceived();
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
        printPayment(paymentGuid) {
            axios({
                url: "Payments/PrintPayment",
                method: "GET",
                responseType: "blob",
                params: {
                    paymentGuid: paymentGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تسديد دفعة.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        print() {
            axios({
                url: "Bills/PrintBill",
                method: "GET",
                responseType: "blob",
                params: {
                    billGuid: this.bill.billGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "فاتورة.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        getStudentBills(id) {
            try {
                if (
                    id != null &&
                    id != undefined &&
                    id != "00000000-0000-0000-0000-000000000000"
                )
                    axios
                        .get("Bills/GetStudentBills?id=" + id)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.bills = response.data.data;
                                this.fiscalYearTitles = this.bills.map(function(
                                    a
                                ) {
                                    return {
                                        billGuid: a.billGuid,
                                        title: a.title
                                    };
                                });
                                this.bills.forEach(element => {
                                    element.installment.firstPaymentDate = moment(
                                        element.installment.firstPaymentDate
                                    ).format("yyyy-MM-DD");

                                    element.payments.forEach(p => {
                                        if (p != null)
                                            if (p.receivedDate != null)
                                                p.receivedDate = moment(
                                                    p.receivedDate
                                                ).format("yyyy-MM-DD");
                                    });
                                });

                                if (
                                    this.bills != null &&
                                    this.bills.length > 0
                                ) {
                                    //select the first bill by default (first bill = last inserted bill)
                                    this.bill = this.bills[0];
                                    this.selectedBillGuid = this.bills[0].billGuid;
                                }
                                this.remainingAndReceived();
                                this.checkPaidPayments();
                            } else {
                                this.$toast.warning(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.refreshTable();
                            console.log(e);
                        });
            } catch (error) {
                console.log(error);
            }
        },
        changeBill() {
            try {
                this.bill = this.bills.find(
                    x => x.billGuid == this.selectedBillGuid
                );

                //Object.assign(this.bill, selectedBill);

                this.remainingAndReceived();
                this.checkPaidPayments();
            } catch (error) {
                console.log(error);
            }
        },
        setIndex(item) {
            var index = this.bill.payments.indexOf(item);
            this.rowIndex = index;
        }
    }
};
</script>
<style>
.myTable {
    border: 1px solid #bdbdbd !important;
}
.myTable th,
.myTable td {
    border: 1px solid #bdbdbd !important;
    border-collapse: collapse;
    text-align: center;
}
</style>
