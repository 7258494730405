<template>
    <v-container fluid>
        <v-card :loading="loading" class="mt-3">
            <Page-Header
                :title="$t('school.students')"
                icon="mdi-account-group"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                :headers="headers"
                :items="students"
                class="pa-5"
                :search="search"
                :loading="loading"
                :loading-text="$t('loading')"
                @dblclick:row="dblClickRow"
            >
                <template v-slot:top>
                    <!--Search bar-->
                    <v-text-field
                        dense
                        outlined
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <!--Save popup-->
                    <v-dialog v-model="dialog" persistent max-width="1500px">
                        <v-card :loading="loading">
                            <v-card-title class="pa-0">
                                <!----------------Tabs Names--------------------->
                                <v-toolbar color="primary" dark>
                                    <v-tabs v-model="tab" align-with-title>
                                        <v-tabs-slider></v-tabs-slider>
                                        <v-tab
                                            class="white--text"
                                            v-for="item in tabsNames"
                                            :key="item"
                                        >
                                            {{ item }}
                                        </v-tab>
                                    </v-tabs>
                                </v-toolbar>
                            </v-card-title>
                            <v-tabs-items v-model="tab">
                                <!----------------Student Info. Tab--------------------->
                                <v-tab-item eager>
                                    <v-form
                                        ref="studentForm"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            hide-details
                                                            dense
                                                            v-model="
                                                                student.fullName
                                                            "
                                                            :rules="rules"
                                                            :label="
                                                                $t(
                                                                    'customer.fullName'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.surname
                                                            "
                                                            :label="
                                                                $t(
                                                                    'customer.surname'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.parentName
                                                            "
                                                            :label="
                                                                $t(
                                                                    'school.parentName'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select
                                                            outlined
                                                            hide-details
                                                            dense
                                                            persistent-hint
                                                            v-model="
                                                                student.relationship
                                                            "
                                                            :items="
                                                                relationships
                                                            "
                                                            item-text="key"
                                                            :label="
                                                                $t(
                                                                    'school.relationship'
                                                                )
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <!---------------------------------->
                                                    <v-col cols="12" md="3">
                                                        <template>
                                                            <div>
                                                                <v-menu
                                                                    ref="menu"
                                                                    v-model="
                                                                        menu
                                                                    "
                                                                    :close-on-content-click="
                                                                        false
                                                                    "
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    min-width="auto"
                                                                >
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-text-field
                                                                            outlined
                                                                            dense
                                                                            hide-details
                                                                            v-model="
                                                                                student.birthday
                                                                            "
                                                                            :label="
                                                                                $t(
                                                                                    'school.birthday'
                                                                                )
                                                                            "
                                                                            readonly
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                        ></v-text-field>
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="
                                                                            student.birthday
                                                                        "
                                                                        :active-picker.sync="
                                                                            activePicker
                                                                        "
                                                                        :max="
                                                                            new Date(
                                                                                Date.now() -
                                                                                    new Date().getTimezoneOffset() *
                                                                                        60000
                                                                            )
                                                                                .toISOString()
                                                                                .substr(
                                                                                    0,
                                                                                    10
                                                                                )
                                                                        "
                                                                        min="1950-01-01"
                                                                        @change="
                                                                            saveDate
                                                                        "
                                                                    ></v-date-picker>
                                                                </v-menu>
                                                            </div>
                                                        </template>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select
                                                            outlined
                                                            dense
                                                            hide-details
                                                            persistent-hint
                                                            v-model="
                                                                student.gender
                                                            "
                                                            :items="gender"
                                                            item-text="key"
                                                            item-value="value"
                                                            :label="
                                                                $t(
                                                                    'school.gender'
                                                                )
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.recordNumber
                                                            "
                                                            :label="
                                                                $t(
                                                                    'school.recordNumber'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select
                                                            outlined
                                                            dense
                                                            hide-details
                                                            persistent-hint
                                                            v-model="
                                                                student.accountGuid
                                                            "
                                                            :items="accounts"
                                                            item-text="nameCode"
                                                            item-value="accountGuid"
                                                            :rules="rules"
                                                            :label="
                                                                $t(
                                                                    'accountTree.account'
                                                                )
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <!---------------------------------->
                                                    <v-col cols="12" md="3">
                                                        <v-select
                                                            outlined
                                                            hide-details
                                                            dense
                                                            :rules="rules"
                                                            persistent-hint
                                                            v-model="
                                                                student.schoolStageGuid
                                                            "
                                                            :items="
                                                                schoolStages
                                                            "
                                                            item-text="stageName"
                                                            item-value="schoolStageGuid"
                                                            :label="
                                                                $t(
                                                                    'school.schoolStage'
                                                                )
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select
                                                            outlined
                                                            dense
                                                            hide-details
                                                            :rules="rules"
                                                            persistent-hint
                                                            v-model="
                                                                student.semesterGuid
                                                            "
                                                            :items="semesters"
                                                            item-text="semesterName"
                                                            item-value="semesterGuid"
                                                            :label="
                                                                $t(
                                                                    'school.semester'
                                                                )
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select
                                                            outlined
                                                            dense
                                                            hide-details
                                                            :rules="rules"
                                                            persistent-hint
                                                            v-model="
                                                                student.classGuid
                                                            "
                                                            :items="classes"
                                                            item-text="className"
                                                            item-value="classGuid"
                                                            :label="
                                                                $t(
                                                                    'school.class'
                                                                )
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.customerGroup
                                                            "
                                                            :label="
                                                                $t(
                                                                    'school.section'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <!---------------------------------->
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.phone
                                                            "
                                                            :label="
                                                                $t(
                                                                    'customer.phone'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.email
                                                            "
                                                            :label="
                                                                $t(
                                                                    'customer.email'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            hide-details
                                                            v-model="
                                                                student.city
                                                            "
                                                            :label="
                                                                $t(
                                                                    'customer.city'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            outlined
                                                            hide-details
                                                            dense
                                                            v-model="
                                                                student.address
                                                            "
                                                            :label="
                                                                $t(
                                                                    'customer.address'
                                                                )
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                    <!---------------------------------->
                                                    <v-col cols="12" md="12">
                                                        <v-textarea
                                                            outlined
                                                            dense
                                                            auto-grow
                                                            rows="1"
                                                            hide-details
                                                            v-model="
                                                                student.notes
                                                            "
                                                            :label="
                                                                $t(
                                                                    'school.studentNotes'
                                                                )
                                                            "
                                                        ></v-textarea>
                                                    </v-col>
                                                    <!---------------------------------->
                                                    <v-col cols="12" md="1">
                                                        <v-switch
                                                            inset
                                                            hide-details
                                                            v-model="
                                                                student.orphan
                                                            "
                                                            :label="
                                                                $t(
                                                                    'school.orphan'
                                                                )
                                                            "
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col cols="12" md="1">
                                                        <v-switch
                                                            inset
                                                            hide-details
                                                            v-model="
                                                                student.status
                                                            "
                                                            :label="
                                                                $t(
                                                                    'school.status'
                                                                )
                                                            "
                                                        ></v-switch>
                                                    </v-col>
                                                    <!---------------------------------->
                                                    <v-col
                                                        v-if="
                                                            customerFiles !=
                                                                undefined &&
                                                                (customerFiles !=
                                                                    null) &
                                                                    (customerFiles.length >
                                                                        0)
                                                        "
                                                        cols="12"
                                                        md="12"
                                                    >
                                                        <v-row>
                                                            <v-col cols="12"
                                                                ><p
                                                                    class="black--text"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            "school.studentFiles"
                                                                        )
                                                                    }}
                                                                </p></v-col
                                                            >
                                                            <v-col cols="12">
                                                                <v-card
                                                                    class="overflow-y-auto"
                                                                    max-height="300"
                                                                    elevation="0"
                                                                    outlined
                                                                >
                                                                    <v-card-text>
                                                                        <v-row>
                                                                            <v-col
                                                                                v-for="file in customerFiles"
                                                                                :key="
                                                                                    file.name
                                                                                "
                                                                                cols="12"
                                                                                md="4"
                                                                            >
                                                                                <download-file
                                                                                    :iconWidth="
                                                                                        50
                                                                                    "
                                                                                    :fileSizeFormat="
                                                                                        file.size
                                                                                    "
                                                                                    :onDelete="
                                                                                        deleteFile
                                                                                    "
                                                                                    :onClose="
                                                                                        closeDeleteFile
                                                                                    "
                                                                                    :file="
                                                                                        file
                                                                                    "
                                                                                    :fileUrl="
                                                                                        file.path
                                                                                    "
                                                                                ></download-file>
                                                                            </v-col> </v-row
                                                                    ></v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" md="12">
                                                        <v-file-input
                                                            v-model="currFiles"
                                                            small-chips
                                                            show-size
                                                            multiple
                                                            hide-details
                                                            @click:clear="
                                                                clearFiles
                                                            "
                                                            @change="
                                                                inputChanged
                                                            "
                                                        >
                                                            <template
                                                                v-slot:selection="{
                                                                    text
                                                                }"
                                                            >
                                                                <v-chip
                                                                    small
                                                                    text-color="white"
                                                                    color="#295671"
                                                                >
                                                                    {{ text }}
                                                                </v-chip>
                                                            </template>
                                                        </v-file-input>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn
                                                    color="primary darken-1 white--text"
                                                    @click="saveStudent"
                                                    :disabled="!valid"
                                                    :min-width="100"
                                                >
                                                    <v-icon
                                                        >mdi-content-save-outline</v-icon
                                                    >
                                                    {{
                                                        $t("school.saveStudent")
                                                    }}
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="red"
                                                    @click="close"
                                                >
                                                    {{ $t("cancel") }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-form>
                                </v-tab-item>
                                <!----------------Bill Tab--------------------->
                                <v-tab-item eager>
                                    <Student-Bill
                                        :studentGuid="studentGuid"
                                        :customerGuid="customerGuid"
                                        :key="dialog"
                                        @close="close"
                                    />
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </v-dialog>
                    <!--Delete popup-->
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                    <!--Delete file confirm-->
                    <confirm-dialog
                        :openDialog="deleteFileDialog"
                        :onClicked="deleteFileConfirm"
                        :onClose="closeDeleteFile"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                </template>
                <template v-slot:[`item.relationship`]="{ item }">
                    {{ relationships[item.relationship].key }}
                </template>
                <template v-slot:[`item.birthday`]="{ item }">
                    {{ item.birthday | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row>
                        <v-col cols="6">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(44)"
                                        class="mr-2"
                                        @click="editItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span> {{ $t("edit") }} </span>
                            </v-tooltip></v-col
                        >
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(45)"
                                        color="red"
                                        @click="deleteItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-delete-outline
                                    </v-icon>
                                </template>
                                <span> {{ $t("delete") }}</span>
                            </v-tooltip></v-col
                        >
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import DownloadFile from "../../components/downloadFile.vue";
import PageHeader from "../../components/PageHeader.vue";
import StudentBill from "./StudentBill.vue";
import moment from "moment";

export default {
    components: { ConfirmDialog, DownloadFile, StudentBill, PageHeader },
    data() {
        return {
            customerGuid: null,
            studentGuid: null,
            tab: null,
            tabsNames: [
                this.$t("school.studentInfo"),
                this.$t("school.billInfo")
            ],
            deletedFile: null,
            deleteFileDialog: false,
            currFiles: [],
            files: [],
            customerFiles: null,
            activePicker: null,
            date: null,
            menu: false,
            valid: true,
            loading: true,
            dialog:
                this.$route.query.showDialog != undefined
                    ? this.$route.query.showDialog
                    : false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            gender: [
                {
                    key: this.$t("male"),
                    value: 0
                },
                {
                    key: this.$t("female"),
                    value: 1
                }
            ],
            relationships: [
                {
                    key: this.$t("relationship.father"),
                    value: 0
                },
                {
                    key: this.$t("relationship.mother"),
                    value: 1
                },
                {
                    key: this.$t("relationship.secondUncle"),
                    value: 2
                },
                {
                    key: this.$t("relationship.secondAunt"),
                    value: 3
                },
                {
                    key: this.$t("relationship.firstUncle"),
                    value: 4
                },
                {
                    key: this.$t("relationship.firstAunt"),
                    value: 5
                },
                {
                    key: this.$t("relationship.brother"),
                    value: 6
                },
                {
                    key: this.$t("relationship.sister"),
                    value: 7
                },
                {
                    key: this.$t("relationship.grandFather"),
                    value: 8
                },
                {
                    key: this.$t("relationship.grandMother"),
                    value: 9
                }
            ],
            headers: [
                {
                    text: this.$t("customer.fullName"),
                    value: "fullName"
                },
                {
                    text: this.$t("school.birthday"),
                    value: "birthday"
                },
                /* {
                    text: this.$t("customer.surname"),
                    value: "surname"
                }, */
                /* {
                    text: this.$t("school.parentName"),
                    value: "parentName"
                }, */
                /* {
                    text: this.$t("school.relationship"),
                    value: "relationship"
                }, */
                {
                    text: this.$t("school.section"),
                    value: "customerGroup"
                },
                {
                    text: this.$t("school.schoolStage"),
                    value: "stageName"
                },
                {
                    text: this.$t("school.semester"),
                    value: "semesterName"
                },
                {
                    text: this.$t("school.class"),
                    value: "className"
                },
                {
                    text: this.$t("customer.address"),
                    value: "address"
                },
                /* {
                    text: this.$t("customer.phone"),
                    value: "phone"
                }, */
                {
                    text: this.$t("school.studentNotes"),
                    value: "notes"
                },
                { text: "", value: "actions" }
            ],
            students: [],
            accounts: [],
            schoolStages: [],
            semesters: [],
            classes: [],
            student: {
                studentGuid: null,
                accountGuid: null,
                customerType: 4,
                customerGroup: null,
                fullName: null,
                surname: null,
                phone: null,
                email: null,
                address: null,
                city: null,
                notes: null,
                fileUrl: null,
                parentName: null,
                relationship: 0,
                birthday: null,
                gender: 0,
                recordNumber: null,
                status: true,
                orphan: false,
                schoolStageGuid: null,
                classGuid: null,
                semesterGuid: null
            },
            defaultStudent: {
                studentGuid: null,
                accountGuid: null,
                customerType: 4,
                customerGroup: null,
                fullName: null,
                surname: null,
                phone: null,
                email: null,
                address: null,
                city: null,
                notes: null,
                fileUrl: null,
                parentName: null,
                relationship: 0,
                birthday: null,
                gender: 0,
                recordNumber: null,
                status: true,
                orphan: false,
                schoolStageGuid: null,
                classGuid: null,
                semesterGuid: null
            },
            bill: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: [],
                installment: {
                    installmentGuid: null,
                    billGuid: null,
                    installmentType: null,
                    installmentState: 1,
                    paymentCount: null,
                    paymentIncrement: null,
                    firstPaymentDate: moment(new Date()).format("yyyy-MM-DD"),
                    guarantor1Name: null,
                    guarantor1Phone: null,
                    guarantor1Address: null,
                    guarantor1FileUrl: null,
                    guarantor2Name: null,
                    guarantor2Phone: null,
                    guarantor2Address: null,
                    guarantor2FileUrl: null,
                    guarantyBy: null
                },
                payments: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(43)) {
            this.loading = true;
            this.refreshData();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        }
    },
    methods: {
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        editItem(item) {
            this.editedIndex = this.students.indexOf(item);
            Object.assign(this.student, item);
            Object.assign(this.student, item.customer);
            this.getCustomerFiles(this.student.customerGuid);

            this.customerGuid = this.student.customerGuid;
            this.studentGuid = this.student.studentGuid;

            if (this.student.birthday != null) {
                this.student.birthday = moment(this.student.birthday).format(
                    "yyyy-MM-DD"
                );
            }

            this.$forceUpdate();
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.students.indexOf(item);
            this.student = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.students[this.editedIndex];
            axios
                .delete("students/DeleteStudent?id=" + deleteItem.studentGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;

            this.editedIndex = -1;
            this.student = Object.assign(this.student, this.defaultStudent);
            this.$refs.studentForm.reset();
            this.$refs.studentForm.resetValidation();

            this.customerGuid = null;
            this.studentGuid = null;
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.student = Object.assign({}, this.defaultStudent);
                this.editedIndex = -1;
            });
        },
        refreshData() {
            axios
                .get("Students/GetStudents")
                .then(response => {
                    this.students = response.data.data;

                    //if customer guid is found in url then open the dialog
                    if (this.$route.query.customerGuid != undefined) {
                        this.customerGuid = this.$route.query.customerGuid;

                        var student = this.students.find(
                            x =>
                                x.customerGuid == this.$route.query.customerGuid
                        );
                        this.studentGuid = student.studentGuid;
                        this.editItem(student);
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("AccountsTree/Get")
                .then(response => {
                    this.accounts = response.data.data.filter(function(obj) {
                        return (
                            obj.parentGuid !=
                            "00000000-0000-0000-0000-000000000000"
                        );
                    });
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Students/GetSemesters")
                .then(response => {
                    this.semesters = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
            axios
                .get("Students/GetSchoolStages")
                .then(response => {
                    this.schoolStages = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Students/GetClasses")
                .then(response => {
                    this.classes = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveStudent() {
            if (this.$refs.studentForm.validate()) {
                if (this.editedIndex == -1) {
                    this.student.studentGuid =
                        "00000000-0000-0000-0000-000000000000";
                }

                var data = new FormData();

                var validFiles = true;

                for (let i = 0; i < this.files.length; i++) {
                    var file = this.files[i];
                    if (file != null && file.size <= 2097152) {
                        data.append("files", file);
                    } else {
                        validFiles = false;
                        break;
                    }
                }

                if (this.student.birthday == "Invalid date") {
                    this.student.birthday = null;
                }

                for (var key in this.student) {
                    if (this.student[key]) data.append(key, this.student[key]);
                }

                if (!validFiles)
                    this.$toast.error(
                        this.$t("error.FileSizeMustBeLessThan2MegaBytes")
                    );
                else
                    axios
                        .post("Students/SaveStudent", data)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                            this.refreshData();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.refreshData();
                            console.log(e);
                        });
            }
        },
        remove(index) {
            this.files.splice(index, 1);
        },
        inputChanged() {
            this.files = [...this.currFiles, ...this.files];
        },
        getCustomerFiles(id) {
            axios.get("Customers/GetCustomerFiles?id=" + id).then(response => {
                this.customerFiles = response.data.data;
            });
        },
        getStudentBill(id) {
            axios
                .get("Bills/GetStudentBill?id=" + id)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.bill = response.data.data;
                        this.bill.installment.firstPaymentDate = moment(
                            this.bill.installment.firstPaymentDate
                        ).format("yyyy-MM-DD");
                        this.$forceUpdate();
                    } else {
                        this.$toast.warning(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshTable();
                    console.log(e);
                });
        },
        deleteFileConfirm() {
            axios
                .delete(
                    "Customers/DeleteCustomerFile?fileName=" + this.deletedFile
                )
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.getCustomerFiles(this.student.customerGuid);
                        this.deleteFileDialog = false;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshTable();
                    this.deleteFileDialog = false;
                    console.log(e);
                });
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
            this.currFiles = [];
            this.files = [];
        },
        downloadFile(file) {
            window.open(file.path);
        },
        print() {
            axios({
                url: "Bills/PrintBill",
                method: "GET",
                responseType: "blob",
                params: {
                    billGuid: this.bill.billGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "فاتورة.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        clearFiles() {
            try {
                this.files = [];
            } catch (error) {
                console.log(error);
            }
        },
        dblClickRow(event, { item }) {
            this.editItem(item);
        }
    }
};
</script>
<style>
#data-grid-demo {
    min-height: 700px;
}
.myTable {
    border: 1px solid #9e9e9e !important;
}
.myTable th,
.myTable td {
    border: 1px solid #9e9e9e !important;
    border-collapse: collapse;
    text-align: center;
    text-align-last: center;
    justify-content: center;
}
.options {
    margin-top: 20px;
    padding: 20px;
    background: #f5f5f5;
}
.options .caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.option > span {
    width: 120px;
    display: inline-block;
}
.option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 350px;
}
#gridContainer {
    height: 440px;
}
</style>
